import React from 'react';
//gatsby
import { Link } from 'gatsby';
//styles
import styled, { css } from 'styled-components';
import { darken, rem } from 'polished';
import vars from 'components/styles/varss';

//styled
const ButtonWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 12px;
  font-size: ${rem('16px')};
  font-weight: bold;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  line-height: 1.5;
  color: ${vars.colors.darkBlue};
  background-color: #fff;
  transition: ${vars.transitions.hover2};
  white-space: nowrap;
  //min-width: 120px;
  border: 1px solid ${vars.colors.darkBlue};
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: ${vars.colors.darkBlue};
  }
  /* Large */
  ${props => props.lg && css`
    font-size: ${rem('18px')};
    line-height: 1.56;
    padding: 12px 24px;
  `}
  /* Small */
  ${props => props.sm && css`
    padding: 6px 16px;
  `}
  /* Extra Small */
  ${props => props.xs && css`
    padding: 8px 14px;
    font-size: ${rem('14px')};
    line-height: 1.7;
    letter-spacing: -0.01em;
  `}
  /* Block */
  ${props => props.block && css`
    display: block;
    width: 100%;
  `}
  /* Green */
  ${props => props.green && css`
    border: none;
    color: ${vars.colors.darkBlue};
    background-color: ${vars.colors.green};
    &:hover,
    &:focus,
    &:active {
      color: ${vars.colors.darkBlue};
      background-color: ${darken(0.1, vars.colors.green)};
    }
  `}
  /* Purple */
  ${props => props.purple && css`
    color: #fff;
    border: none;
    background-color: ${vars.colors.purple};
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: ${darken(0.1, vars.colors.purple)};
    }
  `}
  /* Dark Blue */
  ${props => props.darkBlue && css`
    color: #fff;
    border: none;
    background-color: ${vars.colors.darkBlue};
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: ${darken(0.1, vars.colors.darkBlue)};
    }
  `}
`;

const Button = ({
    children,
    onClick,
    to,
    className,
    sm,
    lg,
    xs,
    block,
    green,
    purple,
    darkBlue,
    target,
  }) => {

  return (
    target === "_blank" ? (
      <a href={to} target="_blank" rel="noreferrer">
        <ButtonWrap
          className={`button ${className}`}
          onClick={onClick}
          sm={sm}
          lg={lg}
          block={block}
          green={green}
          purple={purple}
          darkBlue={darkBlue}>
          {children}
        </ButtonWrap>
      </a>
    ) : (
        <Link to={to ? to : '/#'}>
        <ButtonWrap
          className={`button ${className}`}
          onClick={onClick}
          sm={sm}
          lg={lg}
          block={block}
          green={green}
          purple={purple}
          darkBlue={darkBlue}>
          {children}
        </ButtonWrap>
      </Link>
    )
  );

};

export default Button;