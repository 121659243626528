import React, { useContext } from "react";
//context
import { GlobalContext } from 'context/';
//styles
import styled, { css } from 'styled-components';
import vars from 'components/styles/varss';

const NavToggleWrap = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 24px;
  padding: 5px 2px;
  overflow: hidden;
  position: relative;
  transition: ${vars.transitions.hover1};
  cursor: pointer;
  @media (max-width: ${vars.navBreakpoint}px) {
    display: flex;
  }
  &:hover {
    .nav-toggle-line {
      opacity: 0.8;
    }
  }
  .nav-toggle-line {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 12px;
    transition: ${vars.transitions.hover1};
  }
  /* active */
  ${props => props.active && css`
    .nav-toggle-line {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &:nth-child(1) {
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(45deg);
      }
    }
  `}
`;

const NavToggle = () => {

  //use Context
  const [context, setContext] = useContext(GlobalContext);
  //toggle mobile nav
  const setMobileNavToggle = () => {
    setContext({
      ...context,
      mobileNav: !context.mobileNav,
      mobilePanel: false,
    });
  };

  return (
    <NavToggleWrap
      className="nav-toggle"
      active={context.mobileNav}
      onClick={setMobileNavToggle}>
      <span className="nav-toggle-line"></span>
      <span className="nav-toggle-line"></span>
      <span className="nav-toggle-line"></span>
    </NavToggleWrap>
  );
};

export default NavToggle;