import { css, createGlobalStyle } from 'styled-components'
import { darken, rem } from 'polished';
import vars from 'components/styles/varss';

const typography = css`
  /* My Typography Styles */
  body {
    font-size: ${vars.fonts.fontSize};
    font-family: ${vars.fonts.fontFamily};
    font-weight: ${vars.fonts.fontWeight};
    line-height:  ${vars.fonts.lineHeight};
    color: ${vars.colors.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a,
  button {
    transition: ${vars.transitions.hover2};
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      //outline: none;
    }
    &:active,
    &:focus {
      outline: transparent solid 1px;
    }
    &:active {
      &:focus {
        outline: transparent solid 1px;
      }
    }
    &[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }
  }
  a {
    color: ${vars.colors.blue};
    &:hover,
    &:active,
    &:focus {
      color: ${darken(0.2, vars.colors.blue)};
    }
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: ${rem('20px')};
    }
    &.p1 {
      font-size: ${rem('20px')};
      line-height: 1.6;
    }
    &.p2 {
      font-size: ${rem('18px')};
      line-height: 1.56;
    }
    &.p3 {
      font-size: ${rem('16px')};
      line-height: 1.5;
    }
    &.p4 {
      font-size: ${rem('14px')};
      line-height: 1.7;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-weight: 800;
    margin-bottom: ${rem('20px')};
  }
  h1 {
    font-size: ${rem('64px')};
    line-height: 1.19;
    letter-spacing: -0.02em;
    @media (min-width: ${vars.media.lgMin}) and (max-width: ${vars.media.lgMax}) {
      font-size: ${rem('57px')};
    }
    @media (min-width: ${vars.media.mdMin}) and (max-width: ${vars.media.mdMax}) {
      font-size: ${rem('52px')};
    }
    @media (max-width: ${vars.media.smMax}) {
      font-size: ${rem('44px')};
    }
  }
  h2 {
    font-size: ${rem('52px')};
    line-height: 1.15;
    letter-spacing: -0.02em;
    @media (min-width: ${vars.media.lgMin}) and (max-width: ${vars.media.lgMax}) {
      font-size: ${rem('46px')};
    }
    @media (min-width: ${vars.media.mdMin}) and (max-width: ${vars.media.mdMax}) {
      font-size: ${rem('41px')};
    }
    @media (max-width: ${vars.media.smMax}) {
      font-size: ${rem('36px')};
    }
  }
  h3 {
    font-size: ${rem('40px')};
    line-height: 1.2;
    letter-spacing: -0.01em;
    @media (min-width: ${vars.media.lgMin}) and (max-width: ${vars.media.lgMax}) {
      font-size: ${rem('36px')};
    }
    @media (min-width: ${vars.media.mdMin}) and (max-width: ${vars.media.mdMax}) {
      font-size: ${rem('32px')};
    }
    @media (max-width: ${vars.media.smMax}) {
      font-size: ${rem('28px')};
    }
  }
  h4 {
    font-size: ${rem('32px')};
    line-height: 1.12;
    letter-spacing: -0.01em;
    @media (min-width: ${vars.media.lgMin}) and (max-width: ${vars.media.lgMax}) {
      font-size: ${rem('28px')};
    }
    @media (min-width: ${vars.media.mdMin}) and (max-width: ${vars.media.mdMax}) {
      font-size: ${rem('25px')};
    }
    @media (max-width: ${vars.media.smMax}) {
      font-size: ${rem('22px')};
    }
  }
  h5 {
    font-size: ${rem('28px')};
    line-height: 1.29;
    letter-spacing: -0.01em;
    @media (min-width: ${vars.media.lgMin}) and (max-width: ${vars.media.lgMax}) {
      font-size: ${rem('25px')};
    }
    @media (min-width: ${vars.media.mdMin}) and (max-width: ${vars.media.mdMax}) {
      font-size: ${rem('22px')};
    }
    @media (max-width: ${vars.media.smMax}) {
      font-size: ${rem('19px')};
    }
  }
  h6 {
    font-size: ${rem('24px')};
    line-height: 1.33;
    letter-spacing: -0.01em;
    @media (min-width: ${vars.media.lgMin}) and (max-width: ${vars.media.lgMax}) {
      font-size: ${rem('21px')};
    }
    @media (min-width: ${vars.media.mdMin}) and (max-width: ${vars.media.mdMax}) {
      font-size: ${rem('19px')};
    }
    @media (max-width: ${vars.media.smMax}) {
      font-size: ${rem('17px')};
    }
  }
  a[href="#"], a[href="/#"] {
      pointer-events: none;
  }
`;

export const Typography = createGlobalStyle`${typography}`;

export default typography;