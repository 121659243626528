import React from "react";
//gatsby
import { Link, useStaticQuery, graphql } from 'gatsby';
//router
import { useLocation } from '@reach/router';
//styles
import styled from 'styled-components';
import { rem, darken } from 'polished';
import vars from 'components/styles/varss';

const MobileCTAWrap = styled.div`
  position: fixed;
  right: ${rem('20px')};
  bottom: ${rem('40px')};
  z-index: 9999;
  > a {
    display: flex;
    align-items: center;
    gap: 0 8px;
    background-color: ${vars.colors.capri};
    font-weight: 700;
    font-size: ${rem('16px')};
    height: 44px;
    padding: ${rem('10px')} ${rem('12.5px')};
    border-radius: 12px;
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.25);
    transition: ${vars.transitions.hover1};
    &:hover {
      background-color: ${darken(0.1, vars.colors.capri)};
    }
    svg {
      position: relative;
      top: -2px;
    }
  }
  @media (min-width: ${vars.media.lgMin}) {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
`;

const MobileCTA = () => {

  //get params
  const location = useLocation();

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      wp {
        mobileStickyCta {
          enable_mobile_sticky_cta
          mobile_sticky_cta_text
          mobile_sticky_cta_url
        }
      }
    }
  `);

  const wpMobileCta = data.wp.mobileStickyCta;
  const urls = ['/landing/', '/roi-calculator/', '/partners/'];

  const containsURL = urls.some(element => {
    if (location.pathname.includes(element)) {
      return true;
    }
    return false;
  });

  return (
    <>
      {wpMobileCta.enable_mobile_sticky_cta ? (
        !containsURL ? (
          <MobileCTAWrap>
            <Link to={wpMobileCta.mobile_sticky_cta_url}>
              <svg width={20} height={22} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15 1a1 1 0 1 0-2 0v1H7V1a1 1 0 0 0-2 0v1H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3h-2V1Zm3 7V5a1 1 0 0 0-1-1h-2v1a1 1 0 1 1-2 0V4H7v1a1 1 0 0 1-2 0V4H3a1 1 0 0 0-1 1v3h16ZM2 10h16v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-9Z" fill="#071A2B"/>
              </svg>
              <span>{wpMobileCta.mobile_sticky_cta_text}</span>
            </Link>
          </MobileCTAWrap>
        ) : null
      ) : null}
    </>
  );
};

export default MobileCTA;