import React from "react";
//gatsby
import { Link } from 'gatsby';
//router
import { useLocation } from '@reach/router';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Button from 'components/ui/button';
import Dropdown from 'components/ui/dropdown';

const MenuWrap = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  align-items: center;
  @media (min-width: ${vars.navBreakpoint + 1}px) {
    display: flex;
  }
  > li {
    > a:not(.button) {
      display: flex;
      align-items: center;
      padding: 5px 14px;
      color: #fff;
      font-size: ${rem('16px')};
      line-height: 1.5;
      font-weight: bold;
      &:hover,
      &:focus {
        color: ${vars.colors.capri}!important;
      }
      &:focus {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
      /* current page */
      &[aria-current='page'] {
        color: #fff;
        &:hover,
        &:focus {
          color: ${vars.colors.capri};
        }
        &:focus {
          text-decoration: underline;
          text-decoration-color: transparent;
        }
      }
    }
    > .button {
      margin-left: 14px;
    }
  }
`;

const Menu = ({ menuItems, smallDropdown }) => {

  const location = useLocation();
  const search = location.search;

  return (
    <MenuWrap className="nav-menu">
      {menuItems.nodes.map((menu) => {

        const withChildren = menu.childItems.nodes.length;
        const isBtn = menu.cssClasses[0] === 'is-btn';

        if (withChildren) {
          return (
            <li key={menu.id}>
              <Dropdown menu={menu} smallDropdown={smallDropdown} />
            </li>
          );
        }

        return (
          <li key={menu.id}>
            {isBtn ? (
              <Button to={`${menu.path}${search}`} target={menu.target} className="button" green>
                {menu.label}
              </Button>
            ) : (
              menu.target === "_blank" ? (
                <a href={`${menu.path}${search}`} target="_blank" rel="noreferrer">{menu.label}</a>
              ) : (
                <Link to={`${menu.path}${search}`}>
                  {menu.label}
                </Link>
              )
            )}
          </li>
        );
      })}
    </MenuWrap>
  );
}

export default Menu;