import React from "react";
//gatsby
import { Link } from 'gatsby';
//styles
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';

const MobileNavPanelWrap = styled.div`
  height: calc(100vh - ${vars.headerHeightSm}px);
  overflow-y: auto;
  background-color: #fff;
  padding-top: ${rem('16px')};
  padding-left: ${rem('20px')};
  padding-right: ${rem('20px')};
  padding-bottom: ${rem('24px')};
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transform: translateX(100%);
  transition: ${vars.transitions.hover3};
  /* active */
  ${props => props.active && css`
    transform: translateX(0);
  `}
  .back {
    background: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: ${rem('16px')};
    line-height: 1.5;
    margin-bottom: ${rem('24px')};
    cursor: pointer;
    svg {
      margin-right: ${rem('12px')};
    }
  }
  .label {
    font-weight: 800;
    margin-bottom: ${rem('12px')};
    font-size: ${rem('20px')};
    line-height: 1.4;
    letter-spacing: -0.01em;
  }
  > p:not(.label) {
    max-width: ${rem('500px')};
    padding-right: ${rem('15px')};
  }
  .button {
    margin-top: ${rem('24px')};
  }
  .sublabel {
    font-size: ${rem('16px')};
    line-height: 1.5;
    font-weight: bold;
    color: ${vars.colors.grey5};
    margin-top: ${rem('32px')};
    margin-bottom: ${rem('20px')};
    + ul {
      margin-top: 0;
    }
  }
`;

const MobileNavPanelLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: ${rem('40px')};
  > li {
    > a {
      display: flex;
      align-items: center;
      padding-top: ${rem('8px')};
      padding-bottom: ${rem('8px')};
      font-size: ${rem('18px')};
      font-weight: bold;
      line-height: 1.56;
      color: ${vars.colors.darkBlue};
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 36px;
        width: 36px;
        height: 36px;
        border-radius: 8px;
        background-color: ${vars.colors.grey1};
        margin-right: ${rem('16px')};
        transition: inheret;
      }
      &:hover {
        color: ${vars.colors.capri};
        .icon {
          background-color: ${vars.colors.paleCapri};
        }
      }
      &:focus {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
      &.with-desc {
        align-items: start;
        div {
          p {
            font-weight: normal;
            font-size: ${rem('16px')};
            line-height: 1.5;
            margin-bottom: 0;
          }
        }
      }
      &.bottom-link {
        padding: ${rem('20px')} ${rem('24px')};
        background-color: ${vars.colors.grey1};
        //position: absolute;
        //margin: auto;
        margin-top: ${rem('40px')};
        left: ${rem('20px')};
        bottom: ${rem('24px')};
        right: ${rem('20px')};
        text-decoration: underline;
        border-radius: 12px;
        &:hover {
          .icon {
            background-color: transparent;
          }
        }
        &:focus {
          text-decoration: underline;
          text-decoration-color: transparent;
        }
        .icon {
          min-width: 0!important;
          width: auto!important;
          height: auto!important;
          svg {
            width: ${rem('10px')}!important;
            height: ${rem('10px')}!important;
          }
        }
      }
    }
  }
`;

const MobileNavPanel = ({ open, close, menu, closeMobileNav, search }) => {

  return (
    <MobileNavPanelWrap active={open}>
      <button className="back" onClick={close}><Icon icon="arrow-left" size={10} /> Back</button>
      {menu.cssClasses[0] === 'platform-dropdown' ? (
        <div className="platform">
          <p className="label">{menu.label}</p>
          <p className="p3">{menu.description}</p>
          <Button to={`${menu.path}${search}`} className="button" green sm onClick={closeMobileNav}>See Overview</Button>
          <MobileNavPanelLinks>
            {menu.childItems.nodes.map((item) => {
              let url = item.path;
              if (url.indexOf('#') !== -1) {
                let [pureUrl, hash] = url.split("#");
                url = `${pureUrl}${search}#${hash}`;
              } else {
                url = `${url}${search}`;
              }
              return (
                <li key={item.id} onClick={closeMobileNav}>
                  <Link to={`${url}`} className={item.cssClasses}>
                    <span className="icon">
                      <Icon icon={item.iconClass} size={18} />
                    </span>
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </MobileNavPanelLinks>
        </div>
      ) : menu.cssClasses[0] === 'solutions-dropdown' ? (
        <div className="solutions">
          <p className="label">{menu.label}</p>
          <p className="p3">{menu.description}</p>
          <Button to={`${menu.path}${search}`} className="button" green sm onClick={closeMobileNav}>Learn more</Button>
            {menu.childItems.nodes.map((category) => (
              <div key={category.id}>
                <p className="sublabel">{category.label}</p>
                <MobileNavPanelLinks>
                  {category.childItems.nodes.map((item) => (
                    <li key={item.id} onClick={closeMobileNav}>
                      <Link to={`${item.path}${search}`} className={item.description ? 'with-desc' : null}>
                        <span className="icon">
                          <Icon icon={item.iconClass} size={18} />
                        </span>
                        {item.description ? (
                          <div>
                            {item.label}
                            <p>{item.description}</p>
                          </div>
                        ) : (
                          item.label
                        )}
                      </Link>
                    </li>
                  ))}
                </MobileNavPanelLinks>
              </div>
            ))}
        </div>
      ) : menu.cssClasses[0] === 'resources-dropdown' ? (
        <div className="resources">
          <p className="sublabel">Resources</p>
          <MobileNavPanelLinks>
            {menu.childItems.nodes.map((item) => (
              <li key={item.id} onClick={closeMobileNav}>
                {item.target === "_blank" ? (
                  <a href={`${item.path}${search}`} target="_blank" rel="noreferrer">
                    <span className="icon">
                      <Icon icon={item.iconClass} size={18} />
                    </span>
                    {item.label}
                  </a>
                ) : (
                  <Link to={`${item.path}${search}`}>
                    <span className="icon">
                      <Icon icon={item.iconClass} size={18} />
                    </span>
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </MobileNavPanelLinks>
        </div>
      ) : menu.cssClasses[0] === 'partners-dropdown' ? (
        <div className="partners">
          <p className="label">{menu.label}</p>
          <p className="p3">{menu.description}</p>
          <Button to={`${menu.path}${search}`} className="button" green sm onClick={closeMobileNav}>Learn more</Button>
          <MobileNavPanelLinks>
            {menu.childItems.nodes.map((item) => (
              <li key={item.id} onClick={closeMobileNav}>
                <Link to={`${item.path}${search}`} className={item.cssClasses}>
                  <span className="icon">
                    <Icon icon={item.iconClass} size={18} />
                  </span>
                  {item.label}
                </Link>
              </li>
            ))}
          </MobileNavPanelLinks>
        </div>
      ) : (
        <div>
          <p className="label">{menu.label}</p>
          <p className="p3">{menu.description}</p>
          <MobileNavPanelLinks>
            {menu.childItems.nodes.map((item) => (
              <li key={item.id} onClick={closeMobileNav}>
                <Link to={`${item.path}${search}`} className={item.cssClasses}>
                  <span className="icon">
                    <Icon icon={item.iconClass} size={18} />
                  </span>
                  {item.label}
                </Link>
              </li>
            ))}
          </MobileNavPanelLinks>
        </div>
      )}
    </MobileNavPanelWrap>
  );
};

export default MobileNavPanel;